* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root{
  --color-131921: #131921;
  --color-ff73ab: #ff73ab;
  --color-C20475: #C20475;
  --color-DD0786:#DD0786;
  

}
/* .app{
  background-image: url(../src/assets/bg.svg);
} */

.gradient-border {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
  border-radius: 50%;
  background: radial-gradient(ellipse at top left, #DD0786 0%, transparent 100%);
}

.navbar-container {
  position: relative;
}

.navbar-link {
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
  margin-bottom: -10px;
  transition: transform 0.3s ease;
}

.navbar-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: #DD0786;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.navbar-link.active::after {
  transform: scaleX(1);
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-img{
  transform: rotate(270deg);
}  


.image-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Change the color as desired */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .overlay,
.image-container:active .overlay {
  opacity: 1;
}

.header{
  z-index: 1000;
}
.shopping{
  z-index: 1001;
}


.gallery-modal{
  z-index: 1001;

}